import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

interface OnlineTest {
  _id: string;
  name: string;
  email: string;
  caseId: string;
  expiry: string;
  isSubmitted: boolean;
  questions: any[];
  createdAt: string;
  updatedAt: string;
}

const OnlineTests: React.FC = () => {
  const [onlineTests, setOnlineTests] = useState<OnlineTest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchOnlineTests();
  }, []);

  const fetchOnlineTests = async () => {
    setLoading(true);
    try {
      const response = await axios.get<OnlineTest[]>(`${process.env.REACT_APP_BASE_URI}/OnlineTest/mine`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setOnlineTests(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching online tests:', err);
      setError('Failed to fetch online tests. Please try again later.');
      toast.error('Failed to fetch online tests');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Online Tests</h1>
      
      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : error ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      ) : onlineTests.length === 0 ? (
        <p className="text-gray-600 text-center py-8">No online tests found.</p>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {onlineTests.map((test:any) => (
            <div key={test._id} className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">{test.name}</h2>
                <p className="text-gray-600 mb-1">Case ID: {test.caseId}</p>
                <p className="text-gray-600 mb-1">Expiry: {new Date(test.expiryDate).toLocaleDateString()}</p>
                <p className="text-gray-600 mb-4">
                  Status: 
                  <span className={`ml-2 ${test.isSubmitted ? 'text-green-600' : 'text-red-600'}`}>
                    {test.isSubmitted ? 'Submitted' : 'Not Submitted'}
                  </span>
                </p>
                <p className="text-gray-600 mb-4">Questions: {test.questions.length}</p>
                
                <div className="flex justify-between items-center mt-4">
                 <Link
                  to={`/online-test/${test._id}`}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                 >
                  Start
                 </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OnlineTests;