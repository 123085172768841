import logo from "assets/images/logo.webp";
import { useAuthStore } from "store/useAuthStore";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";
import PhotosUploader from "components/Forms/ImageUploader";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const Header = ({ user, setUser }: { user: any; setUser: any }) => {
  return (
    <header className="flex items-center min-h-[60px] justify-between px-4 py-3 bg-white shadow-md dark:bg-zink-800">
      <img src={logo} className="h-6" alt="" />
      <div
        onClick={() => {
          setUser(null);
          localStorage.removeItem("token");
        }}
        className="cursor-pointer text-sm font-semibold text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-200"
      >
        <p className="">Logout as {user && user.name}</p>
      </div>
    </header>
  );
};

const validationSchema = yup.object({
  name: yup.string().required(),
  address: yup.string().required(),
  phone: yup.string().required(),
  website: yup.string(),
  logo: yup.string(),
  theme: yup.object(),
});

const ProfileCreation = () => {
  const { user, setUser } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
      website: "",
      logo: "",
      theme: {
        primary: "#000000",
        secondary: "#000000",
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await axios.post(
          `${process.env.REACT_APP_BASE_URI}/business/create`,
          {
            ...values,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Your business profile has been created");
        startTimerAndReload();
      } catch (error: any) {
        if (!error.response) {
          return toast.error("Network error. Please try again.");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
        if (typeof error.response.data.error === "string") {
          return toast.error(error.response.data.error);
        }
        toast.error("Something went wrong.");
      } finally {
        setLoading(false);
      }
    },
  });

  const stepsScreenValidation = () => {
    if (step === 1) {
      if (!formik.values.name) {
        formik.setFieldError("name", "Name is required");
        formik.setFieldTouched("name", true, false);
        return false;
      }
    } else if (step === 2) {
      if (!formik.values.address) {
        formik.setFieldError("address", "Address is required");
        formik.setFieldTouched("address", true, false);
        return false;
      }
      if (!formik.values.phone) {
        formik.setFieldError("phone", "Phone is required");
        formik.setFieldTouched("phone", true, false);
        return false;
      }
    } else if (step === 3) {
    }
    return true;
  };

  const nextStep = () => {
    if (stepsScreenValidation()) {
      setStep(step + 1);
    }
  };

  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (images.length > 0) {
      formik.setFieldValue("logo", images[0]);
    } else {
      formik.setFieldValue("logo", "");
    }
  }, [images]);

  const [timer, setTimer] = useState(3);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const startTimerAndReload = () => {
    setIsTimerRunning(true);
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      window.location.reload();
    }, 5000);
  };

  return (
    <div>
      <Toaster />
      <Header user={user} setUser={setUser} />
      <div className="flex flex-col items-center p-12 min-h-screen bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public">
        <h1 className="text-2xl font-semibold text-center mb-4 dark:text-zink-100">
          {step === 1
            ? "Let's get started by creating your Business Profile"
            : step === 2
            ? "Add your Business Address and Phone"
            : "Give your business a customized theme"}
        </h1>
        <div className="flex flex-col mt-2 border p-10 items-center justify-center bg-white max-w-[400px] mx-auto rounded-lg">
          {step === 1 && (
            <div className="mb-3">
              <label
                htmlFor="name"
                className="inline-block mb-2 text-md text-base font-medium"
              >
                What is your Business Name?
              </label>
              <input
                type="text"
                id="name"
                disabled={loading}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
                className="form-input border-slate-200 w-full min-w-[300px] dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter name"
              />
              {formik.errors.name && formik.touched.name && (
                <div className="text-red-500 text-sm mt-2">
                  {formik.errors.name}
                </div>
              )}
            </div>
          )}
          {step === 2 && (
            <div>
              <div className="mb-3">
                <label
                  htmlFor="address"
                  className="inline-block mb-2 text-md text-base font-medium"
                >
                  What is your Business Address?
                </label>
                <input
                  type="text"
                  id="address"
                  disabled={loading}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="address"
                  className="form-input border-slate-200 w-full min-w-[300px] dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter address"
                />
                {formik.errors.address && formik.touched.address && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.address}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="phone"
                  className="inline-block mb-2 text-md text-base font-medium"
                >
                  What is your Business Phone?
                </label>
                <input
                  type="text"
                  id="phone"
                  disabled={loading}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} // Add this line to update touched state on blur
                  name="phone"
                  className="form-input border-slate-200 w-full min-w-[300px] dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter phone"
                />
                {formik.errors.phone && formik.touched.phone && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.phone}
                  </div>
                )}
              </div>
            </div>
          )}
          {step === 3 && (
            <div>
              <div className="mb-3">
                <label
                  htmlFor="website"
                  className="inline-block mb-2 text-md text-base font-medium"
                >
                  What is your Business Website?{" "}
                  <span className="text-xs text-gray-400 dark:text-gray-300">
                    Optional
                  </span>
                </label>
                <input
                  type="text"
                  id="website"
                  disabled={loading}
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="website"
                  className="form-input border-slate-200 w-full min-w-[300px] dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter website"
                />
                {formik.errors.website && formik.touched.website && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.website}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="logo"
                  className="inline-block mb-2 text-md text-base font-medium"
                >
                  Upload your business logo{" "}
                  <span className="text-xs text-gray-400 dark:text-gray-300">
                    Optional
                  </span>
                </label>
                <PhotosUploader
                  addedPhotos={images}
                  onChange={setImages}
                  maxPhotos={1}
                />
                {formik.errors.logo && formik.touched.logo && (
                  <div className="text-red-500 text-sm mt-2">
                    {formik.errors.logo}
                  </div>
                )}
              </div>
              {/* <div
                className="flex items-center flex-col justify-between"
                style={{ width: "100%" }}
              >
                <div>
                  <label
                    htmlFor="primary"
                    className="inline-block mb-2 text-md text-base font-medium"
                  >
                    Primary Color{" "}
                    <span className="text-xs text-gray-400 dark:text-gray-300">
                      Optional
                    </span>
                  </label>
                  <input
                    type="color"
                    id="primary"
                    disabled={loading}
                    value={formik.values.theme.primary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="theme.primary"
                    className="form-input border-slate-200 w-full min-w-[300px] dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter primary color"
                  />
                </div>
                <div>
                  <label
                    htmlFor="secondary"
                    className="inline-block mb-2 text-md text-base font-medium"
                  >
                    Secondary Color{" "}
                    <span className="text-xs text-gray-400 dark:text-gray-300">
                      Optional
                    </span>
                  </label>
                  <input
                    type="color"
                    id="secondary"
                    disabled={loading}
                    value={formik.values.theme.secondary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="theme.secondary"
                    className="form-input border-slate-200 w-full min-w-[300px] dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter secondary color"
                  />
                </div>
              </div> */}
            </div>
          )}
          <div className="flex items-center justify-center mt-4 flex-col">
            {step === 3 && (
              <AnimationButton
                loadingText="Finishing up..."
                onClick={formik.handleSubmit}
                className="min-w-[300px] items-center justify-center text-center"
                loading={loading}
                disabled={loading}
                label="Finish"
              />
            )}
            {step < 3 && (
              <AnimationButton
                onClick={nextStep}
                className="min-w-[300px] items-center justify-center text-center"
                loading={false}
                label="Next Step"
              />
            )}
            {step > 1 && (
              <AnimationButton
                mode="outlined"
                onClick={() => setStep(step - 1)}
                className="min-w-[300px] items-center mt-2 justify-center text-center"
                loading={false}
                label="Previous Step"
              />
            )}
            {isTimerRunning && (
              <div className="text-xs text-gray-400 dark:text-gray-300 mt-2">
                This page will reload in {timer} seconds
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCreation;
