import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import PhotosUploader from "../Forms/ImageUploader";

interface KYCProps {
  onChange: (value: string) => void;
  name: string;
}

const KYC: React.FC<KYCProps> = ({ onChange }) => {
  const [images, setImages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [data, setData] = useState<any>();

  useEffect(()=>{
    onChange("not approved");
  }, [])

  const handleKycSubmit = async () => {
    if (images.length === 0) {
      toast.error("Please upload an image");
      return;
    }

    setIsUploading(true);

    try {
      const data = new FormData();
      data.append("cnicImage", images[0]);

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/kyc`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const approved = res.data.status === "approved";
      const kycId = res.data.kyc._id;
      onChange(approved ? `approved-${kycId}` : "not approved");
      toast.success(
        `KYC ${approved ? "approved" : "not approved"} automatically`
      );
      setData(res.data?.data);
    } catch (error: any) {
      toast.error("Failed to perform KYC");
      onChange("not approved");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <PhotosUploader addedPhotos={images} onChange={setImages} maxPhotos={1} />
      <div className="flex flex-col gap-1 mt-5">
        {data && data.result.name && (
          <React.Fragment>
            {" "}
            <p className="text-lg font-semibold">
              {data.result.name} {data.result.surname}
            </p>
            <p>Passport Number: {data.result.passport_no}</p>
            <p>Date of Birth: {data.result.date_of_birth}</p>
            <p className="text-center text-sm text-gray-500 mt-3">
              Your KYC is successfully done
            </p>
          </React.Fragment>
        )}
      </div>
      {!data && (
        <p className="text-sm text-gray-500 mt-3">Your KYC is not done yet</p>
      )}
      {!data && (
        <button
          type="button"
          onClick={handleKycSubmit}
          className="bg-blue-500 text-white p-2 rounded mt-2"
          disabled={isUploading}
        >
          {isUploading ? "Uploading..." : "Perform KYC"}
        </button>
      )}
    </div>
  );
};

export default KYC;
