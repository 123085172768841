import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import StepProgress from "components/ProgramForm/StepProgress";
import StepCompletion from "components/ProgramForm/StepCompletion";

const ProgramProgress: React.FC = () => {
  const [progress, setProgress] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/client-progress/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProgress(response.data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProgress();
  }, [id]);

  if (loading) return <div className="text-center mt-5">Loading...</div>;
  if (error)
    return <div className="text-center text-red-500 mt-5">{error}</div>;

  const currentStepProgress = progress.stepsProgress.find(
    (stepProgress: any) => !stepProgress.completed
  );

  if (!currentStepProgress)
    return <StepCompletion 
    stages={progress.stages}
    />
    
  return (
    <div className="min-h-screen bg-white flex flex-col items-center w-full">
      <header className="w-full bg-white text-white py-4">
        <div className="max-w-4xl mx-auto flex items-center justify-between">
          <h1 className="text-3xl font-bold">{progress.program.title}</h1>
          {progress.program.countryFlag && (
            <img
              src={progress.program.countryFlag}
              alt="Country Flag"
              className="w-10 h-10 rounded-full"
            />
          )}
        </div>
      </header>
      <main className="flex-grow w-full max-w-4xl mx-auto p-4 bg-white shadow-md rounded mt-4">
        <h2 className="text-2xl font-semibold mb-4">
          Current Step: {currentStepProgress.step.title}
        </h2>
        <StepProgress stepProgress={currentStepProgress} clientId={progress._id} />
      </main>
    </div>
  );
};

export default ProgramProgress;
