import React, { useState } from 'react';

interface Answer {
  _id: string;
  question: string;
  answer: string;
}

interface Question {
  _id: string;
  options: string[];
  answer: number;
}

interface OnlineTest {
  _id: string;
  clientId: {
    name: string;
    email: string;
  };
  questions: Question[];
  answers: Answer[];
  passingThreshold: number;
  isSubmitted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface ResultsProps {
  onlineTest: OnlineTest;
}

const Results: React.FC<ResultsProps> = ({ onlineTest }:{
  onlineTest: OnlineTest;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const checkAnswer = (questionId: string, answer: string): boolean => {
    const question = onlineTest.questions.find(
      (question) => question._id === questionId
    );
    if (!question) return false;
    return question.options[question.answer] === answer;
  };

  const calculateScore = (): number => {
    let score = 0;
    onlineTest.questions.forEach((question) => {
      const answer = onlineTest.answers.find(
        (answer) => answer.question === question._id
      );
      if (answer && question.options[question.answer] === answer.answer) {
        score++;
      }
    });
    return score;
  };

  const isPassed = (): boolean => {
    const percentageThreshold = onlineTest.passingThreshold;
    const percentageScore =
      (calculateScore() / onlineTest.questions.length) * 100;
    return percentageScore >= percentageThreshold;
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300"
      >
        View Results
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full max-h-[80vh] overflow-auto">
            <div className="p-6">
              <h2 className="text-2xl font-bold text-gray-800 mb-2">
                Online Test Results
              </h2>
              <p className="text-gray-600 mb-6">
                View the results of the test below
              </p>

              {/* <div className="bg-gray-100 p-4 rounded-md mb-6">
                <p className="text-lg font-semibold text-gray-800">{onlineTest.clientId.name}</p>
                <p className="text-sm text-gray-600">{onlineTest.clientId.email}</p>
              </div> */}

              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Answers:</h3>
                {onlineTest.answers.map((answer, index) => (
                  <div key={answer._id} className="mb-2 bg-gray-100 p-4 rounded-md">
                    <p className="text-sm font-semibold text-gray-700">Question {index + 1}:</p>
                    <p
                      className={`text-sm ${
                        checkAnswer(answer.question, answer.answer)
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {answer.answer}
                    </p>
                  </div>
                ))}
              </div>

              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Score:</h3>
                <p className="text-3xl font-bold text-gray-800">
                  {calculateScore()} / {onlineTest.questions.length}
                </p>
                <p
                  className={`text-lg font-semibold ${
                    isPassed() ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {isPassed() ? "You passed the test" : "You failed the test"}
                </p>
              </div>

              <div className="text-sm text-gray-600">
                <p>Submitted: {onlineTest.isSubmitted ? "Yes" : "No"}</p>
                <p>Created At: {new Date(onlineTest.createdAt).toLocaleString()}</p>
                <p>Updated At: {new Date(onlineTest.updatedAt).toLocaleString()}</p>
              </div>

              <button
                onClick={() => setIsModalOpen(false)}
                className="mt-6 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Results;