import React from "react";
import moment from "moment";

const StepCompletion = ({ stages }: { stages: any }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full text-center">
        <h2 className="text-3xl font-bold mb-6 text-black">
          Program Stages
        </h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created At
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {stages.map((stage: any, index: number) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-lg text-gray-700 font-medium">
                  {stage?.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                  {stage?.description}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-600">
                  {moment(stage?.createdAt).format("DD/MM/YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
     
      </div>
    </div>
  );
};

export default StepCompletion;
