import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Results from './Results';
import { MyTimer } from 'components/Timer/Timer';
import { FiClock, FiCheck, FiAlertCircle, FiLoader } from 'react-icons/fi';

interface OnlineTest {
  _id: string;
  clientId: any;
  businessId: any;
  questions: {
    _id: string;
    title: string;
    options: string[];
  }[];
  coverImage: string;
  expiry: string;
  isSubmitted: boolean;
  timeLimit: number;
  startedAt: Date;
  isReady: boolean;
  createdAt: string;
  updatedAt: string;
}

interface Answer {
  question: string;
  answer: string;
}

const OnlineTest: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [onlineTest, setOnlineTest] = useState<OnlineTest | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [answers, setAnswers] = useState<Answer[]>([]);

  useEffect(() => {
    handleGetOnlineTest();
  }, []);

  const handleGetOnlineTest = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/OnlineTest/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setOnlineTest(res.data);
    } catch (error) {
      console.error(error);
      toast.error('Failed to load the test. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const validateTest = () => {
    return answers.length === onlineTest?.questions.length;
  };

  const handleSubmit = async () => {
    if (!validateTest()) {
      toast.error('Please answer all questions before submitting.');
      return;
    }
    try {
      setSubmitting(true);
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/OnlineTest/${id}/submit`,
        { answers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      toast.success('Test submitted successfully');
      window.location.reload();
    } catch (error: any) {
      if (error.response?.data?.message === 'Time limit exceeded.') {
        toast.error(
          'Time limit exceeded. Your test has been automatically submitted.'
        );
      } else {
        toast.error('An error occurred while submitting. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleStartTest = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/OnlineTest/${id}/start`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      handleGetOnlineTest();
    } catch (error) {
      toast.error('Failed to start the test. Please try again.');
    }
  };

  const isTheTestExpired = () => {
    if (!onlineTest?.startedAt || !onlineTest?.timeLimit) return false;
    const startedAt = new Date(onlineTest.startedAt).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - startedAt;
    const timeDifferenceInMinutes = timeDifference / 60000;
    return timeDifferenceInMinutes > onlineTest.timeLimit;
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <FiLoader className="animate-spin h-12 w-12 text-indigo-600" />
      </div>
    );
  }

  if (!onlineTest) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <div className="text-center">
          <FiAlertCircle className="mx-auto h-12 w-12 text-red-500" />
          <h2 className="mt-2 text-lg font-semibold text-gray-900">
            Online test not found
          </h2>
          <p className="mt-2 text-sm text-gray-500">
            The test you're looking for doesn't exist or has been removed.
          </p>
        </div>
      </div>
    );
  }

  if (onlineTest.isSubmitted) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
        <div className="text-center mb-8">
          <FiCheck className="mx-auto h-16 w-16 text-green-500" />
          <h2 className="mt-2 text-3xl font-bold text-gray-900">
            Test Submitted
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            Your test has been successfully submitted.
          </p>
        </div>
        {/* @ts-ignore */}
        <Results onlineTest={onlineTest} />
      </div>
    );
  }

  if (isTheTestExpired()) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="text-center">
          <FiAlertCircle className="mx-auto h-16 w-16 text-red-500" />
          <h2 className="mt-2 text-3xl font-bold text-gray-900">
            Test Expired
          </h2>
          <p className="mt-2 text-lg text-gray-600">
            The time limit for this test has been exceeded.
          </p>
          <p className="mt-2 text-sm text-gray-500">
            Please contact the administrator for assistance.
          </p>
        </div>
      </div>
    );
  }

  if (onlineTest?.businessId === '66a9ed8034d42fcedbc7aaf2') {
    return (
      <div className="min-h-screen bg-[#38afa9] py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
          <div className="relative h-48 bg-[#38afa9]">
            <img
              src={onlineTest.coverImage}
              alt="Test cover"
              className="w-full h-full object-cover opacity-50"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <h1 className="text-4xl font-extrabold text-white tracking-tight">
                Preliminary Evaluation Test
              </h1>
            </div>
          </div>

          <div className="p-8">
            <div className="flex justify-between items-start mb-8">
              <div>
                <h2 className="text-2xl font-bold text-gray-800">
                  {onlineTest.clientId.name}
                </h2>
                <p className="text-[#38afa9]">{onlineTest.clientId.email}</p>
              </div>
              <div className="text-right">
                <h3 className="text-lg font-semibold text-gray-700">
                  Time Limit
                </h3>
                <p className="text-[#38afa9] flex items-center justify-end mt-1 text-xl">
                  <FiClock className="mr-2" />
                  {onlineTest.timeLimit} minutes
                </p>
              </div>
            </div>

            {!onlineTest.isReady ? (
              <div className="text-center py-12">
                <p className="text-xl text-gray-600 mb-8">
                  Shall we start the test?
                </p>
                <button
                  onClick={handleStartTest}
                  className="px-8 py-3 bg-[#38afa9] text-white font-bold rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                >
                  Start
                </button>
              </div>
            ) : (
              <>
                <div className="mb-8 bg-gray-100 rounded-lg p-4 sticky top-0 z-10">
                  <MyTimer
                    expiryTimestamp={
                      new Date(
                        new Date(onlineTest.startedAt).getTime() +
                          onlineTest.timeLimit * 60000
                      )
                    }
                  />
                </div>
                <div className="space-y-12">
                  {onlineTest.questions.map((question, index) => (
                    <div
                      key={index}
                      className="bg-gray-50 rounded-xl p-6 shadow-md"
                    >
                      <h3 className="text-xl font-semibold text-gray-800 mb-4">
                        Question {index + 1}: {question.title}
                      </h3>
                      <div className="space-y-3">
                        {question.options.map((option, i) => (
                          <label
                            key={i}
                            className="flex items-center space-x-3 cursor-pointer hover:bg-gray-100 p-2 rounded-md transition duration-200"
                          >
                            <input
                              type="radio"
                              name={question._id}
                              value={option}
                              onChange={(e) => {
                                const newAnswers = answers.filter(
                                  (answer) => answer.question !== question._id
                                );
                                setAnswers([
                                  ...newAnswers,
                                  {
                                    question: question._id,
                                    answer: e.target.value,
                                  },
                                ]);
                              }}
                              className="form-radio h-5 w-5 text-[#38afa9]"
                            />
                            <span className="text-gray-700">{option}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-12 flex justify-end">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="inline-flex items-center px-8 py-3 border border-transparent text-lg font-medium rounded-full shadow-sm text-white bg-[#38afa9] disabled:opacity-50 transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    {submitting ? (
                      <>
                        <FiLoader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                        Submitting...
                      </>
                    ) : (
                      'Submit Assessment'
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
  if (onlineTest?.businessId === '66cde879656128f03c987f8e') {
    return (
      <div className="min-h-screen bg-yellow-200 py-10 px-4 sm:px-6 lg:px-12">
        <div className="max-w-4xl mx-auto bg-yellow-50 rounded-lg shadow-lg overflow-hidden">
          <div className="relative h-48 sm:h-60 bg-yellow-400">
            <img
              src={onlineTest.coverImage}
              alt="Test cover"
              className="w-full h-full object-cover opacity-70"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-0">
              <h1 className="text-3xl sm:text-4xl font-bold text-white drop-shadow-md tracking-wide text-center">
                Assessment Test
              </h1>
            </div>
          </div>

          <div className="p-6 sm:p-8">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
              <div className="mb-4 sm:mb-0">
                <h2 className="text-2xl sm:text-3xl font-semibold text-gray-800">
                  {onlineTest.clientId.name}
                </h2>
                <p className="text-sm sm:text-md text-gray-500">
                  {onlineTest.clientId.email}
                </p>
              </div>
              <div className="text-left sm:text-right sm:flex-shrink-0">
                <h3 className="text-md sm:text-lg font-semibold text-gray-600">
                  Time Limit
                </h3>
                <p className="text-yellow-600 flex flex-col sm:flex-row items-start sm:items-center justify-end mt-1 text-md sm:text-xl font-semibold">
                  <FiClock className="mr-1 sm:mr-2 h-4 w-4 sm:h-5 sm:w-5" />
                  <span className="leading-tight sm:leading-normal">
                    {onlineTest.timeLimit} minutes
                  </span>
                </p>
              </div>
            </div>

            {!onlineTest.isReady ? (
              <div className="text-center py-12">
                <p className="text-md sm:text-lg text-gray-600 mb-4">
                  Ready to begin the test?
                </p>
                <button
                  onClick={handleStartTest}
                  className="px-6 sm:px-8 py-2 sm:py-3 bg-yellow-500 text-white font-semibold rounded-full shadow transition-transform duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                >
                  Start Test
                </button>
              </div>
            ) : (
              <>
                <div className="mb-8 bg-white rounded-lg p-4 sm:p-6 shadow sticky top-0 z-20">
                  <MyTimer
                    expiryTimestamp={
                      new Date(
                        new Date(onlineTest.startedAt).getTime() +
                          onlineTest.timeLimit * 60000
                      )
                    }
                  />
                </div>
                <div className="space-y-6 sm:space-y-8">
                  {onlineTest.questions.map((question, index) => (
                    <div
                      key={index}
                      className="bg-white border border-gray-300 rounded-lg p-4 sm:p-6 shadow-sm"
                    >
                      <h3 className="text-md sm:text-lg font-semibold text-gray-800 mb-4">
                        {`Question ${index + 1}: ${question.title}`}
                      </h3>
                      <div className="space-y-3">
                        {question.options.map((option, i) => (
                          <label
                            key={i}
                            className="flex items-center space-x-3 sm:space-x-4 cursor-pointer hover:bg-gray-100 p-3 rounded-lg transition duration-200"
                          >
                            <input
                              type="radio"
                              name={question._id}
                              value={option}
                              onChange={(e) => {
                                const newAnswers = answers.filter(
                                  (answer) => answer.question !== question._id
                                );
                                setAnswers([
                                  ...newAnswers,
                                  {
                                    question: question._id,
                                    answer: e.target.value,
                                  },
                                ]);
                              }}
                              className="form-radio h-4 sm:h-5 w-4 sm:w-5 text-yellow-500"
                            />
                            <span className="text-gray-700 font-medium">
                              {option}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-8 sm:mt-12 flex justify-end">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="inline-flex items-center px-6 sm:px-8 py-2 sm:py-3 border border-transparent text-md sm:text-lg font-semibold rounded-full shadow-md text-white bg-yellow-500 disabled:opacity-50 transition-transform duration-300 ease-in-out transform hover:scale-110"
                  >
                    {submitting ? (
                      <>
                        <FiLoader className="animate-spin -ml-1 mr-3 h-4 sm:h-5 w-4 sm:w-5 text-white" />
                        Submitting...
                      </>
                    ) : (
                      'Submit Assessment'
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (onlineTest?.businessId === '66cde879656128f03c987f8e') {
    return (
      <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="bg-white shadow-xl rounded-lg overflow-hidden">
            <img
              src={onlineTest.coverImage}
              alt="Test cover"
              className="w-full h-64 object-cover"
            />
            <div className="p-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                Final Assessment
              </h1>
              <div className="flex justify-between items-start mb-8">
                <div>
                  <h2 className="text-xl font-semibold text-gray-700">
                    {onlineTest.clientId.name}
                  </h2>
                  <p className="text-gray-500">{onlineTest.clientId.email}</p>
                </div>
                <div className="text-right">
                  <h3 className="text-lg font-semibold text-gray-700">
                    Instructions
                  </h3>
                  <p className="text-red-500 flex items-center justify-end mt-1">
                    <FiClock className="mr-1" />
                    {onlineTest.timeLimit} minutes to complete
                  </p>
                </div>
              </div>

              {onlineTest.isReady ? (
                <>
                  <div className="mb-8">
                    <MyTimer
                      expiryTimestamp={
                        new Date(
                          new Date(onlineTest.startedAt).getTime() +
                            onlineTest.timeLimit * 60000
                        )
                      }
                    />
                  </div>
                  <div className="space-y-8">
                    {onlineTest.questions.map((question, index) => (
                      <div
                        key={index}
                        className="border-b border-gray-200 pb-6"
                      >
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">
                          {index + 1}. {question.title}
                        </h3>
                        <div className="space-y-4">
                          {question.options.map((option, i) => (
                            <label
                              key={i}
                              className="flex items-center space-x-3 cursor-pointer"
                            >
                              <input
                                type="radio"
                                name={question._id}
                                value={option}
                                onChange={(e) => {
                                  const newAnswers = answers.filter(
                                    (answer) => answer.question !== question._id
                                  );
                                  setAnswers([
                                    ...newAnswers,
                                    {
                                      question: question._id,
                                      answer: e.target.value,
                                    },
                                  ]);
                                }}
                                className="form-radio h-5 w-5 text-indigo-600"
                              />
                              <span className="text-gray-700">{option}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-8 flex justify-end">
                    <button
                      onClick={handleSubmit}
                      disabled={submitting}
                      className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                    >
                      {submitting ? (
                        <>
                          <FiLoader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                          Submitting...
                        </>
                      ) : (
                        'Submit Test'
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <div className="flex justify-center mt-8">
                  <button
                    onClick={handleStartTest}
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Start Test
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <img
            src={onlineTest.coverImage}
            alt="Test cover"
            className="w-full h-64 object-cover"
          />
          <div className="p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Final Assessment
            </h1>
            <div className="flex justify-between items-start mb-8">
              <div>
                <h2 className="text-xl font-semibold text-gray-700">
                  {onlineTest.clientId.name}
                </h2>
                <p className="text-gray-500">{onlineTest.clientId.email}</p>
              </div>
              <div className="text-right">
                <h3 className="text-lg font-semibold text-gray-700">
                  Instructions
                </h3>
                <p className="text-red-500 flex items-center justify-end mt-1">
                  <FiClock className="mr-1" />
                  {onlineTest.timeLimit} minutes to complete
                </p>
              </div>
            </div>

            {onlineTest.isReady ? (
              <>
                <div className="mb-8">
                  <MyTimer
                    expiryTimestamp={
                      new Date(
                        new Date(onlineTest.startedAt).getTime() +
                          onlineTest.timeLimit * 60000
                      )
                    }
                  />
                </div>
                <div className="space-y-8">
                  {onlineTest.questions.map((question, index) => (
                    <div key={index} className="border-b border-gray-200 pb-6">
                      <h3 className="text-xl font-semibold text-gray-800 mb-4">
                        {index + 1}. {question.title}
                      </h3>
                      <div className="space-y-4">
                        {question.options.map((option, i) => (
                          <label
                            key={i}
                            className="flex items-center space-x-3 cursor-pointer"
                          >
                            <input
                              type="radio"
                              name={question._id}
                              value={option}
                              onChange={(e) => {
                                const newAnswers = answers.filter(
                                  (answer) => answer.question !== question._id
                                );
                                setAnswers([
                                  ...newAnswers,
                                  {
                                    question: question._id,
                                    answer: e.target.value,
                                  },
                                ]);
                              }}
                              className="form-radio h-5 w-5 text-indigo-600"
                            />
                            <span className="text-gray-700">{option}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-8 flex justify-end">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                  >
                    {submitting ? (
                      <>
                        <FiLoader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                        Submitting...
                      </>
                    ) : (
                      'Submit Test'
                    )}
                  </button>
                </div>
              </>
            ) : (
              <div className="flex justify-center mt-8">
                <button
                  onClick={handleStartTest}
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Start Test
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineTest;
