import Dashboard from "pages/Dashboard/Analytics";
import Kycs from "pages/KYC/KYCs";
import Support from "pages/Support/Support";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import SupportDetails from "pages/Support/SupportDetails";
import UserProfile from "pages/UserProfile";
import OnlineTest from "pages/OnlineTest";

import Faqs from "pages/Faqs";

import ProgramProgress from "pages/ProgramProgress/Program";
import Register from "pages/Authentication/Register";
import OnlineTests from "pages/OnlineTests";

import Step from "pages/Steps/Step";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

let authProtectedRoutes: Array<RouteObject> = [

  // Dashboard
  { path: "/", component: Dashboard },

  { path: "/dashboard", component: Dashboard },

  // KYC
  { path: "/kycs", component: Kycs },

  { path: "/program/:id", component: ProgramProgress },

  // Support
  { path: "/support", component: Support },
  { path: "/support/:id", component: SupportDetails },

  // Steps
  { path: "/step-details/:id", component: Step },

  // Online Test
  {path: "/online-tests", component: OnlineTests},
  
  // profile
  { path: "/user-profile", component: UserProfile },

  // Faqs
  { path: "/faqs", component: Faqs },
  
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  {path: "/online-test/:id", component: OnlineTest},
];

export { authProtectedRoutes, publicRoutes };

