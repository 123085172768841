import {
  Activity,
  AlertTriangle,
  Archive,
  Users,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "Dashboard",
    label: "Dashboard",
    link: "/#",
    icon: <Activity />,
  },
  {
    label: "Programs",
    isTitle: true,
  },
  {
    label:"Online Tests",
    link: "/online-tests",
    icon: <Archive />,
  }
];

export { menuData };

