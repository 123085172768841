import React from "react";
import { Loader } from "lucide-react";

const AnimationButton = ({
  onClick,
  loading,
  disabled,
  label,
  className,
  mode="contained"
}: {
  onClick: () => void;
  loading: boolean;
  disabled?: boolean;
  loadingText?: string;
  label?: string;
  className?: string;
  mode?: "contained" | "outlined";
}) => {
  return (
    <React.Fragment>
      <button
        onClick={onClick}
        type="button"
        disabled={disabled}
        className={`flex items-center min-h-[39px]  btn ${mode === "contained" ? "bg-black text-white border-black hover:text-white hover:bg-black/80 hover:border-black/80 focus:text-white focus:bg-black/80 focus:border-black/80 focus:ring focus:ring-custom-100 active:text-white active:bg-black/80 active:border-black/80 active:ring active:ring-custom-100 dark:ring-custom-400/20" :  "bg-gray-100 text-black hover:bg-gray-200"} ${className}`}
      >
        {loading ? (
          <span
          className="flex items-center justify-center w-full h-full"
          >
            <Loader className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
            {/* {loadingText} */}
          </span>
        ) : (
          label
        )}
      </button>
    </React.Fragment>
  );
};

export default AnimationButton;
