import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import KYC from "../FormInputs/KYC";

interface StepProgressProps {
  stepProgress: any;
  clientId: string;
}

const StepProgress: React.FC<StepProgressProps> = ({
  stepProgress,
  clientId,
}) => {
  const [formData, setFormData] = useState<any>({});
  const [isUploading, setIsUploading] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [errors, setErrors] = useState<string[]>([]);

  const handleKycChange = (value: string) => {
    // finding the field type of "kyc" and setting the value to the form data
    const field = stepProgress.step.form.fields.find(
      (field: any) => field.type === "kyc"
    );
    setFormData({
      ...formData,
      [field.label]: value,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    //@ts-ignore
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name } = event.target;
    const file = event.target.files?.[0];

    if (file) {
      setIsUploading((prevState) => ({ ...prevState, [name]: true }));

      try {
        const data = new FormData();
        data.append("file", file);

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/upload`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const url = res.data.url;

        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [name]: url,
        }));

        toast.success("File uploaded successfully");
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsUploading((prevState) => ({ ...prevState, [name]: false }));
      }
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const newErrors: string[] = [];

    stepProgress.step.form.fields.forEach((field: any) => {
      if (field.required && !formData[field.label]) {
        newErrors.push(`${field.label} is required`);
      }
    });

    if (newErrors.length > 0) {
      setErrors(newErrors);
      toast.error("Please fill in all required fields");
    } else {
      setErrors([]);

      try {
        const responsePayload = {
          formResponses: [
            {
              form: stepProgress.step.form._id,
              response: stepProgress.step.form.fields.map((field: any) => ({
                title: field.label,
                value: formData[field.label] || "",
                fieldType: field.type,
              })),
            },
          ],
        };

        await axios.post(
          `${process.env.REACT_APP_BASE_URI}/client-progress/${clientId}/step/${stepProgress.step._id}`,
          responsePayload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        toast.success("Form submitted successfully");
      } catch (error: any) {
        toast.error("Failed to submit form");
      }
    }
  };

  return (
    <div className="bg-white shadow-md rounded p-6 mb-4">
      <h3 className="text-lg font-semibold mb-2">{stepProgress.step.title}</h3>
      <p className="mb-4">{stepProgress.step.description}</p>
      {stepProgress.step.form && (
        <form onSubmit={handleFormSubmit}>
          {stepProgress.step.form.fields.map((field: any, index: any) => (
            <div key={index} className="mb-4">
              <label className="block mb-2">
                {field.label}
                {field.required && <span className="text-red-500"> *</span>}
              </label>
              {field.type === "text" && (
                <input
                  type="text"
                  name={field.label}
                  placeholder={field.placeholder}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              )}
              {field.type === "textarea" && (
                <textarea
                  name={field.label}
                  placeholder={field.placeholder}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              )}
              {field.type === "select" && (
                <select
                  name={field.label}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                >
                  {field.options.map((option: any, idx: any) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
              {field.type === "radio" &&
                field.options.map((option: any, idx: any) => (
                  <label key={idx} className="block">
                    <input
                      type="radio"
                      name={field.label}
                      value={option}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    {option}
                  </label>
                ))}
              {field.type === "checkbox" &&
                field.options.map((option: any, idx: any) => (
                  <label key={idx} className="block">
                    <input
                      type="checkbox"
                      name={field.label}
                      value={option}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    {option}
                  </label>
                ))}
              {field.type === "date" && (
                <input
                  type="date"
                  name={field.label}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              )}
              {field.type === "time" && (
                <input
                  type="time"
                  name={field.label}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                />
              )}
              {field.type === "image" && (
                <input
                  type="file"
                  accept="image/*"
                  name={field.label}
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded"
                />
              )}
              {field.type === "pdf" && (
                <input
                  type="file"
                  accept="application/pdf"
                  name={field.label}
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded"
                />
              )}
              {field.type === "kyc" && (
                <>
                  <KYC onChange={handleKycChange} name={field.label} />
                </>
              )}
              {isUploading[field.label] && (
                <p className="text-blue-500">Uploading...</p>
              )}
            </div>
          ))}
          {errors.length > 0 && (
            <div className="mb-4">
              {errors.map((error, idx) => (
                <p key={idx} className="text-red-500">
                  {error}
                </p>
              ))}
            </div>
          )}
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded"
            disabled={Object.values(isUploading).some((uploading) => uploading)}
          >
            Submit
          </button>
        </form>
      )}
      {!stepProgress.step.form && (
        <p className="text-gray-500">
          No form available for this step.{" "}
          <span>
            Please wait for the admin to approve your current step or add a form
            to this step.
          </span>
        </p>
      )}
    </div>
  );
};

export default StepProgress;
